.main-content {
    position: relative;

    // Navbar
    .navbar-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        background-color: black;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    // Container
    .container-fluid {
        @include media-breakpoint-up(md) {
            padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
            padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important;
        }
    }
}


// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {

    @each $breakpoint,
    $dimension in $grid-breakpoints {

        &-#{$breakpoint} {

            @include media-breakpoint-up(#{$breakpoint}) {

                // Left
                &.fixed-left + .main-content {
                    margin-left: $navbar-vertical-width;
                } // Right
                &.fixed-right + .main-content {
                    margin-right: $navbar-vertical-width;
                }
            }
        }
    }
}
